import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2';
import './DougnutGraph.scss';
import classNames from 'classnames';
import { ReactComponent as RefreshIcon } from '../../../../assets/svg/refresh.svg';
import PropTypes from 'prop-types';
import Loader from '../../../ui/loader/Loader';

export default class DougnutGraph extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            selectedItem: 0,
        };
    };

    chartReference = {};

    options = {
        cutoutPercentage: 92,
        legend: {
            display: false
        },
        onHover: (evt, item) => {
            this.chartReference.chartInstance.update();
            if(item[0]) {
                item[0]._model.outerRadius = 120;
                if(item[0]._index + 1 !== this.state.selectedItem) {
                    this.setState({ selectedItem: item[0]._index + 1 });
                }
            }
            else if(this.state.selectedItem) {
                this.setState({ selectedItem : 0 });
            }
        },
        animationDuration: 100,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        },
        tooltips: {
            enabled: false
        },
    }

    render() {
        const items = this.props.data && !this.props.isLoading ? this.props.data.datasets[0].labels.map((label, index) => {
            return <div key={label} className="doughnut-item"><div className={classNames("item-color", {'selected-item-color': this.state.selectedItem === index + 1})} style={{backgroundColor: this.props.data.datasets[0].backgroundColor[index]}}></div><p>{label}</p><p>{this.props.data.datasets[0].data[index]}</p></div>
        }) : null;

        return (
            <div className="doughnut-holder">
                <header>
                    <h6>{this.props.title}</h6>
                    <RefreshIcon className="refresh-button" onClick={() => this.props.onRefresh()}/>
                </header>
                <div className="graph-info-holder">
                    <h6>{this.props.total}</h6>
                    <p>{this.props.totalTitle}</p>
                </div>
                <div className="graph">
                    {this.props.data && !this.props.isLoading ? 
                    <Doughnut data={this.props.data} options={this.options} height={240} width={240} ref={(reference) => this.chartReference = reference }/> :
                    <div className="loading-surface">
                        <Loader/>
                    </div>}
                </div>
                <div className="graph-items">
                    {items}
                </div>
            </div>
        )
    }
}

DougnutGraph.defaultProps = {
    data: {
        datasets: [{
            labels: [
                'Dutch', 
                'Chinese', 
                'German', 
                'English', 
                'French', 
                'Spanish', 
                'Portuguese', 
                'Italian',
            ],
            data: [233, 54, 151, 147, 52, 58, 40, 45],
            backgroundColor: [
                '#F7681E',
                '#06BBD1',
                '#DB162F',
                '#20BF55',
                '#F2B724',
                '#9E35F4',
                '#EC0092',
                '#268EDC',
            ],
            borderWidth: 0,
        }],
    },
    onRefresh: () => console.log("refresh"),
    totalTitle: "Total sessions",
    total: 0,
    title: "Languages"
};

DougnutGraph.propTypes = {
    data: PropTypes.object,
    onRefresh: PropTypes.func.isRequired,
    title: PropTypes.string,    
    total: PropTypes.number,
    totalTitle: PropTypes.string,
};