import React, { Component } from 'react'
import './DateRangePicker.scss';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';

export default class DateSelection extends Component {

    constructor(props) {
        super(props)
        moment.locale('nl');
    
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            focusedInput: null,
        }
    }

    focusChangeHandler = (focusedInput) => {
        this.setState({ focusedInput });
    }

    datesChangeHandler = (startDate, endDate) => {
        this.setState({ startDate, endDate });
        if(startDate && endDate) {
            if(startDate === endDate) {
                const endTime = endDate;
                console.log(moment(endTime.utc().endOf('day').unix() * 1000 - 86399000));
                console.log(moment(endTime.utc().endOf('day').unix() * 1000));
                this.props.onDatesChanged(moment(endTime.endOf('day').utc().unix() * 1000 - 86399000).unix(), moment(endTime.utc().endOf('day').unix() * 1000).unix());
            }
            else {
                console.log(startDate.utc().startOf('day'));
                console.log(endDate.utc().endOf('day'));
                this.props.onDatesChanged(startDate.utc().startOf('day').unix(), endDate.utc().endOf('day').unix());
            }
        }
    }

    render() {
        return (
            <div className="date-picker">
                <DateRangePicker
                    startDateId="startDate"
                    endDateId="endDate"
                    minimumNights={0}
                    startDate={this.state.startDate ? this.state.startDate.locale('nl') : this.state.startDate}
                    endDate={this.state.endDate ? this.state.endDate.locale('nl') : this.state.endDate}
                    onDatesChange={({ startDate, endDate }) => this.datesChangeHandler(startDate, endDate)}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={this.focusChangeHandler}
                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    daySize={34}
                />
            </div>
        )
    }
}
