import React from 'react';
import './MenuItem.scss';

import classNames from 'classnames';
import { withRouter } from "react-router-dom";

const MenuItem = props => {
    
    const {history} = props;

    return (
        <li onClick={() => { history.push('/' + props.value); props.onClick();  }} className={classNames('menu-item', { 'menu-item-active': props.active })}>
        <span/>
        <span className="fa-li">
            {props.icon}
        </span>{props.children}
        </li>
    )
}

export default withRouter(MenuItem);

