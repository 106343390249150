import React from 'react';
import './Loader.scss';

import classNames from 'classnames';

export default function Loader(props) {
    return (
        <div className={classNames("loader", props.className, { "loader-button": props.inButton })}/>
    )
}
