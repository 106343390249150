import React, { Component } from 'react'
import classNames from 'classnames';

import './Dropdown.scss';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '../../../assets/svg/dd-arrow.svg';
import onClickOutside from "react-onclickoutside";
import CheckBox from '../checkBox/CheckBox';

class Dropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            listOpen: false,
            selectedItem: props.options ? props.options.find(item => {return item.uuid === props.selectedValue}) : null,
            selectedItems: props.multiple && props.selectAll && props.options ? props.options : [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.options && prevState.selectedItem) {
            let newState = prevState;
            const options = nextProps.options.filter(option => {
                return option.uuid === prevState.selectedItem.uuid;
            });
            if(options.length === 0) {
                newState.selectedItem = null;
                newState.selectedItems = [];
                return newState;
            }
        }
        else if (nextProps.options && nextProps.selectedValue) {
            let newState = prevState;
            newState.selectedItem = nextProps.options.find(item => item.uuid === nextProps.selectedValue);
            return newState;
        }
        return prevState;
    }

    handleClickOutside(){
        this.setState({
            listOpen: false
        })
    }

    toggleList(){
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }))
    }

    handleSelect = item => {
        if(!this.props.multiple) {
            this.setState({
                selectedItem: this.props.menu ? this.state.selectedItem : item,
                listOpen: false,
            })
            this.props.onSelect(item.uuid);
        }
        else {
            let selectedItems = this.state.selectedItems;
            if(!selectedItems.includes(item)) {
                selectedItems.push(item);
            }
            else {
                selectedItems = selectedItems.filter(option => item !== option);
            }
            this.setState({ selectedItems });
            this.props.onSelect(selectedItems.map(item => item.uuid));
        }
    }

    handleSelectAll = () => {
        let selectedItems;
        if(this.state.selectedItems.length === this.props.options.length) {
            selectedItems = [];
            this.setState({ selectedItems });
        }
        else {
            selectedItems = this.props.options;
            this.setState({ selectedItems });
        }
        this.props.onSelect(selectedItems.map(item => item.uuid));
    }

    render() {
        const {options, multiple} = this.props
        const {listOpen, selectedItem, selectedItems} = this.state
        const filteredOptions = selectedItem && options ? options.filter(item => {
            return item.uuid !== selectedItem.uuid;
        }) : options;

        const singleOptions = options ? <ul className="dd-list">
            {this.props.filterSelected ? filteredOptions.map((item) => (
                <li className="dd-list-item" key={item.uuid} onClick={() => this.handleSelect(item)}>{item.name}</li>
            )): 
            options.map((item) => (
                <li className="dd-list-item" key={item.uuid} onClick={() => this.handleSelect(item)}>{item.name}</li>
            ))}
        </ul> : null;

        const multipleOptions = options ? <ul className="dd-list">
            <li className={classNames("dd-list-item-multi", "select-all")} onClick={() => this.handleSelectAll()}><CheckBox checked={selectedItems.length === this.props.options.length}/><p>Select all</p></li>
            {options.map((item) => (
                <li className="dd-list-item-multi" key={item.uuid} onClick={() => this.handleSelect(item)}><CheckBox checked={selectedItems.filter(option => option.uuid === item.uuid).length > 0}/><p>{item.name}</p></li>
            ))}
        </ul> : null;

        const multipleHeader = (selectedItems.length > 0 ? (selectedItems.length > 1 ? this.props.multipleText + ' (' + selectedItems.length + ')': selectedItems[0].name) : this.props.placeholder);

        return (
            <div className="dropdown">
                {this.props.title ? <p>{this.props.title}</p> : null}
                <div className={classNames('dd-wrapper', this.props.className, { 'dd-wrapper-open': listOpen })}>
                    <div className="dd-header" onClick={() => this.toggleList()}>
                        <div className={classNames("dd-header-title", {"dd-placeholder" : this.props.placeholder && !selectedItem, 'multiple': selectedItems.length > 0})}>{multiple ? multipleHeader : (selectedItem ? selectedItem.name : this.props.placeholder)}</div>
                        <span><Arrow className={classNames("dd-arrow", {up: this.state.listOpen})}/></span>
                    </div>
                    {options && listOpen ? (multiple ? multipleOptions : singleOptions) : null}
                </div>
            </div>
        )
    }
}

Dropdown.defaultProps = {
    onSelect: value => console.log(value)
}

Dropdown.propTypes = {
    options: PropTypes.array,
    onSelect: PropTypes.func,
    filterSelected: PropTypes.bool,
    selectedValue: PropTypes.any,
}

export default onClickOutside(Dropdown);