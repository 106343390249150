import React, { Component } from 'react'
import MenuItem from '../../ui/menuItem/MenuItem'
import Dropdown from '../../ui/dropdown/Dropdown';

import './Menu.scss';

export default class Menu extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            activeItem: props.selectedMenuItem,
        }
    }

    selectItem = item => {
        this.setState({activeItem: item});
        this.props.onSelectMenuItem(item);
    }
    
    render() {
        const dropdown = this.props.customers && this.props.customers.length > 1 ? 
        <div>
            <Dropdown options={this.props.customers} onSelect={this.props.onSelectCustomer} selectedValue={this.props.selectedCustomer}/>
            <div className="seperator"/>
        </div> : null;
        
        const menuItems = this.props.menuItems.map(item => {
            return (<MenuItem key={item.key} value={item.key} icon={item.icon} onClick={() => this.selectItem(item)} active={this.state.activeItem.key === item.key}>{item.name}</MenuItem>)
        })

        const menu = <ul className="side-menu">{menuItems}</ul>

        return (
            <div className="side-menu">
                {dropdown}
                {menu}
            </div>
        )
    }
}
