import React, { Component } from 'react';
import './StopAnalytics.scss';
import BarGraph from '../BarGraph/BarGraph';
import { getDistribtion } from '../../../../api/Api';

class StopAnalytics extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            stopData: null,
        }
    }

    componentDidMount() {
        this.updateStops();
    }

    componentDidUpdate(prevProps){ 
        if(prevProps !== this.props) {
            this.updateStops();
        }
    }

    updateStops = () => {
        getDistribtion(this.props.user.accessToken, 'sessions', 'stops', this.props.params)
        .then(data => {
            const stopData = data;
            stopData.labels = data.datasets[0].labels;
            stopData.datasets[0].backgroundColor = '#268EDC';
            stopData.datasets[0].hoverBackgroundColor = '#1678C2';
            stopData.datasets[0].barThickness = 15;
            this.setState({ stopData });
        })
    }

    render() {
        return (
            <div className="stop-analytics">
                <BarGraph onRefresh={this.updateStops} title="Stops" data={this.state.stopData}/>
            </div>
        )
    }
}

export default StopAnalytics;