import React, { Component } from 'react';
import './Dashboard.scss';
import Menu from './menu/Menu';

import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as SignOutIcon } from '../../assets/svg/sign_out.svg';
// import { ReactComponent as ExportIcon } from '../../assets/svg/export.svg';
// import { ReactComponent as ReportsIcon } from '../../assets/svg/reports.svg';

import { Route, withRouter } from "react-router-dom";
import IconButton from '../ui/iconButton/IconButton';

import PropTypes from 'prop-types';
import Home from './home/Home';


class Dashboard extends Component {

    menuItems = [
        { key: 'dashboard', name: 'Dashboard', icon: <HomeIcon/>},
        // { key: 'export', name: 'Export', icon: <ExportIcon/>},
        // { key: 'reports', name: 'Reports', icon: <ReportsIcon/>},
    ]

    settings = [
        { uuid: "header", name: "Settings" },
        { uuid: "1", name: "Setting1" },
        { uuid: "2", name: "Setting2" },
        { uuid: "3", name: "Setting3" },
        { uuid: "4", name: "Setting4" },
        { uuid: "5", name: "Setting5" },
    ]

    constructor(props) {
        super(props)
        const {location} = props;

        const selectedItem = this.menuItems.filter(item => {return(item.key === location.pathname.split("/")[1])})[0]

        this.state = {
            selectedCustomer: props.customer,
            selectedMenuItem: selectedItem ? selectedItem : this.menuItems[0],
        }
    }
    
    menuItemSelectedHandler = item => {
        if(this.state.selectedMenuItem.key !== item.key) {
            this.setState({ selectedMenuItem: item });
        }
    }

    customerSelectedHandler = customer => {
        this.setState({ selectedCustomer: customer });
        this.props.onCustomerSelect(customer);
    }
    
    onSettingsSelected = setting => {

    }

    render() {
        return (
            <div className="grid-container">
                <div className="header">
                    {/*<Dropdown menu className="settings-dropdown" options={this.settings} onSelect={this.onSettingsSelected} selectedValue={this.settings[0].uuid}/>*/}
                    <IconButton className="logout-button" onClick={() => this.props.onLogout()}><SignOutIcon/></IconButton>
                </div>
                <div className="menu">
                    <Menu 
                        customers={this.props.customers} 
                        selectedCustomer={this.state.selectedCustomer} 
                        onSelectCustomer={this.customerSelectedHandler} 
                        menuItems={this.menuItems} 
                        selectedMenuItem={this.state.selectedMenuItem}
                        onSelectMenuItem={this.menuItemSelectedHandler} />
                </div>
                <div className="main">
                    <Route exact path="/" render={(props) => <Home {...props} user={this.props.user} selectedCustomer={this.state.selectedCustomer} />}/>
                    <Route path="/dashboard" render={(props) => <Home {...props} user={this.props.user} selectedCustomer={this.state.selectedCustomer} />}/>
                </div>  
            </div>
        )
    }
}

Dashboard.propTypes = {
    customers: PropTypes.arrayOf(PropTypes.object).isRequired,
    customer: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    onCustomerSelect: PropTypes.func.isRequired,
}

export default withRouter(Dashboard);