import React, { Component } from 'react';
import Button from '../../ui/button/Button';

import RadioButton from '../../ui/radioButton/RadioButton';
import { getCustomers } from '../../../api/Api';
import Loader from '../../ui/loader/Loader';

class CustomerSelect extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            customers: null,
            selectedCustomer: null,
        };
    };

    componentDidMount() {
        getCustomers(this.props.user.accessToken).then(
            customers => {
                this.props.setCustomers(customers);
                if(customers.length === 1) {
                    this.props.onSelect(customers[0].uuid);
                }
                else {
                    this.setState({customers: customers, selectedCustomer: customers[0].uuid});
                }
            }
        ).catch(
            err => console.log(err)
        )
    }

    submitHandler = event => {
        event.preventDefault();
        this.props.onSelect(this.state.selectedCustomer);
    }

    onChangeValue = value => {
        this.setState({selectedCustomer: value});
    }

    render() {
        const options = this.state.customers ? this.state.customers.length > 0 ? this.state.customers.map(customer => {
            return <RadioButton key={customer.uuid} value={customer.uuid} onSelect={this.onChangeValue} selected={this.state.selectedCustomer === customer.uuid}>{customer.name}</RadioButton>;
        }):
        <p>You dont have access to any accounts</p>:
        null;
        
        return (
            this.state.customers ? <div className="root">
                <h1>Choose an account</h1>
                {this.state.customers && this.state.customers.length > 0 ? <p>You have access to the accounts below. Make your choise and click continue</p> : null}
                <div>
                    {options}
                </div>
                <Button onClick={this.submitHandler} disabled={!this.state.selectedCustomer}>Continue</Button>
                <p className={"link"} onClick={this.props.onGoBack}>Log out</p>
            </div>:
            <div className="loading-container"><Loader/></div>
        )
    }
}

CustomerSelect.propTypes = {
    
}

export default CustomerSelect;
