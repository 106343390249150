import React, { Component } from 'react';
import './Home.scss';
import { getCustomers, getLanguages, getTours, getKeyFigures } from '../../../api/Api';
import Button from '../../ui/button/Button';
import Dropdown from '../../ui/dropdown/Dropdown';
import TabLayout from '../../ui/tabLayout/TabLayout';
import InfoBox from './infoBox/InfoBox';
import DateRangePicker from '../../ui/dateRangePicker/DateRangePicker';
import moment from 'moment';
import SessionAnalytics from './SessionAnalytics/SessionAnalytics';
import TourAnalytics from './TourAnalytics/TourAnalytics';
import StopAnalytics from './StopAnalytics/StopAnalytics';
import SegmentAnalytics from './SegmentAnalytics/SegmentAnalytics';
import Loader from '../../ui/loader/Loader';
import Axios from 'axios';

class Home extends Component {

    tabs = [
        { name: "Sessions", value: "sessions" },
        { name: "Tours", value: "tours" },
        { name: "Stops", value: "stops" },
        { name: "Segments", value: "segments" },
    ];

    constructor(props) {
        super(props)
    
        this.state = {
            applications: null,
            selectedApplication: null,
            selectedCustomer: null,
            keyFiguresLoading: false,
            requestBody: {
                filter_languages: null,
                filter_tours: null,
                application_uuid: null,
                filter_period_from: moment().startOf('month').add(6,'hour').unix(),
                filter_period_to: moment().unix(),
            },
            keyFigures: null,
        }
    }

    componentDidMount() {
        this.tabChangeHandler(this.tabs[0].value);
        this.updateCustomer();
    }

    updateCustomer = () => {
        getCustomers(this.props.user.accessToken)
        .then(res => {
            const applications = res.filter(customer => customer.uuid === this.state.selectedCustomer)[0].applications;
            this.setState({ applications });
            this.handleAppChange(applications[0].uuid);
        })
        .catch(err => {

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedCustomer !== this.state.selectedCustomer) {
            this.updateCustomer();
        }
    }
 
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.selectedCustomer !== prevState.selectedCustomer) {
            let newState = prevState;
            newState.selectedCustomer = nextProps.selectedCustomer;
            return newState;
        }
        return prevState;
    }

    handleAppChange = selectedApplication => {
        let requestBody = this.state.requestBody;
        requestBody.application_uuid = selectedApplication;
        this.setState({ selectedApplication, requestBody });
        getLanguages(this.props.user.accessToken, selectedApplication)
        .then(res => {
            const languages = res.cms_languages.map(language => {
                return {
                    uuid: language.code,
                    name: language.name,
                }
            });
            let requestBody = this.state.requestBody;
            requestBody.filter_languages = languages.map(lang => { return lang.uuid }).join(',');
            this.setState({ languages, requestBody });
            this.tabChangeHandler(this.state.selectedTab);
        })
        .catch(err => {
            console.log(err.response);
        });
        getTours(this.props.user.accessToken, selectedApplication)
        .then(res => {
            const tours = res.cms_tours.map(tour => {
                return {
                    uuid: tour.uuid,
                    name: tour.name,
                }
            });
            let requestBody = this.state.requestBody;
            requestBody.filter_tours = tours.map(tour => { return tour.uuid }).join(',');
            this.setState({ tours, requestBody });
            this.tabChangeHandler(this.state.selectedTab);
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    handleLanguagesChange = languages => {
        let requestBody = this.state.requestBody;
        requestBody.filter_languages = languages.join(',');
        requestBody.filter_languages = requestBody.filter_languages.length > 0 ? requestBody.filter_languages : this.state.languages.map(language => { return language.uuid }).join(',');
        console.log(languages.join(','));
        this.setState({ requestBody });
        this.updateKeyFigures();
    }

    handleToursChange = tours => {
        let requestBody = this.state.requestBody;
        requestBody.filter_tours = tours.join(',');
        requestBody.filter_tours = requestBody.filter_tours.length > 0 ? requestBody.filter_tours : this.state.tours.map(tour => { return tour.uuid }).join(',');
        console.log(tours.join(','));
        this.setState({ requestBody });
        this.updateKeyFigures();
    }

    tabChangeHandler = selectedTab => {
        this.setState({ selectedTab })
        this.updateKeyFigures(selectedTab);
    }

    updateKeyFigures = tab => {
        const params = this.state.requestBody;
        this.figureCancel && this.figureCancel();
        if(params && params.application_uuid && params.filter_languages && params.filter_tours && params.filter_period_from && params.filter_period_to) {
            this.setState({ keyFiguresLoading: true });
            getKeyFigures(this.props.user.accessToken, tab ? tab : this.state.selectedTab, this.state.requestBody, new Axios.CancelToken(c => this.figureCancel = c))
            .then(keyFigures => this.setState({ keyFigures, keyFiguresLoading: false }))
            .catch(err => this.setState({ keyFiguresLoading: false }));
        }
    }

    dateChangedHandler = (startDate, endDate) => {
        let requestBody = this.state.requestBody;
        requestBody.filter_period_from = startDate;
        requestBody.filter_period_to = endDate;
        this.setState({ requestBody });
        this.updateKeyFigures();
    }

    render() {
        const filters = this.state.applications && this.state.applications.length > 0 ? this.state.applications.map((app, index) => {
            return <div key={index} className="app-item"><Button deviceGroup selected={this.state.selectedApplication === app.uuid} onClick={() => this.handleAppChange(app.uuid)}>{app.name}</Button></div>;
        }): <div className="loading-applications"><Loader/></div>;
        const keyFigures = this.state.keyFigures && !this.state.keyFiguresLoading ? <div className="keyfigures">{this.state.keyFigures.map(figure => {
            return <InfoBox key={figure.name} title={figure.name} info={figure.information} time={figure.name.includes('duration')}/>
        })}</div> : <div className="loading-keyfigures"><Loader/></div>;
        const sessionAnalytics = this.state.selectedTab === "sessions" ? <SessionAnalytics user={this.props.user} params={this.state.requestBody}/> : null;
        const tourAnalytics = this.state.selectedTab === "tours" ? <TourAnalytics user={this.props.user} params={this.state.requestBody}/> : null;
        const stopAnalytics = this.state.selectedTab === "stops" ? <StopAnalytics user={this.props.user} params={this.state.requestBody}/> : null;
        const segmentAnalytics = this.state.selectedTab === "segments" ? <SegmentAnalytics user={this.props.user} params={this.state.requestBody}/> : null;

        return (
            <div className="home">
                <div className="home-header">
                    <div className="app-container">
                        {filters}
                    </div>
                    <div className="filter-container">
                        <DateRangePicker onDatesChanged={this.dateChangedHandler} startDate={moment(this.state.requestBody.filter_period_from * 1000)} endDate={moment(this.state.requestBody.filter_period_to * 1000)}/>
                        <Dropdown placeholder="Select Language" options={this.state.languages} onSelect={this.handleLanguagesChange} multiple selectAll multipleText="Multiple languages"/>
                        <Dropdown placeholder="Select Tour" options={this.state.tours} onSelect={this.handleToursChange} multiple selectAll multipleText="Multiple tours"/>
                    </div>
                    <TabLayout tabs={this.tabs} onChange={this.tabChangeHandler}/>
                </div>
                <div className="analytics-container">
                    {keyFigures}
                    {sessionAnalytics}
                    {tourAnalytics}
                    {stopAnalytics}
                    {segmentAnalytics}
                </div>
            </div>
        )
    }
}

export default Home;