import React, { Component } from 'react'
import Button from '../../ui/button/Button';
import Input from '../../ui/inputField/Input';
import PropTypes from 'prop-types';

class Login extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            email: "",
            password: "",
        }

        this.submitHandler.bind(this);
        this.handleEmailChange.bind(this);
        this.handlePasswordChange.bind(this);
    }
    
    handleEmailChange = event => {
        this.setState({ email: event.target.value });
        this.setState({ emailError: "" });
    }

    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
        this.setState({ passwordError: "" });
    }

    submitHandler = event => {
        event.preventDefault();

        this.setState({ emailError: !this.state.email ? "No email specified." : "" });
        this.setState({ passwordError: !this.state.password ? "No password specified." : "" });

        if(this.state.email && this.state.password) {
            this.props.onSubmit(this.state.email, this.state.password);
        }
    }

    render() {
        return (
            <div className="root">
                <h1>Log in</h1>
                <form>
                    <Input 
                        className={"email"}
                        label={"Email"} 
                        type={"email"}
                        autoComplete={"email"}
                        onChange={this.handleEmailChange}
                        error={this.state.emailError || this.props.loginError}
                    />
                    <Input 
                        className={"password"}
                        label={"Password"} 
                        type={"password"}
                        autoComplete={"password"}
                        onChange={this.handlePasswordChange}
                        error={this.state.passwordError}
                    />
                    <Button onClick={this.submitHandler} loading={this.props.loading}>Log in</Button>
                </form>
                <p className={"link"} onClick={this.props.onForgot}>Forgot password?</p>
            </div>
        )
    }
}

Login.propTypes = {
    emailError: PropTypes.string,
    passwordError: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onForgot: PropTypes.func,
    loginError: PropTypes.string,
    loading: PropTypes.bool,
}

export default Login;