import React, { Component } from 'react';
import './RadioButton.scss';

import classNames from 'classnames';

class RadioButton extends Component {

    render() {
        return (
            <div className={classNames("radio-button", { "radio-button-selected": this.props.selected })} onClick={() => this.props.onSelect(this.props.value)}>
                <span className="radio"/>
                <p className="radio-text">{this.props.children}</p>
            </div>
        )
    }
}

export default RadioButton;