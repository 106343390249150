import Axios from "axios";
import moment from 'moment';

export const hasNull = target => {
    for (let member in target) {
        if (target[member] == null)
            return true;
    }
    return false;
}

export const getCustomers = (accessToken, cancelToken) => {
    const request = Axios.get('/auth/users/me/', { cancelToken: cancelToken, headers: {'Authorization': 'Bearer ' + accessToken}});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data.customers);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
};

export const getTours = (accessToken, application, cancelToken) => {
    const request = Axios.get('/api/v1/cms/tours', { cancelToken: cancelToken, headers: {'Authorization': 'Bearer ' + accessToken}, params: {application_uuid: application }})
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
}

export const getLanguages = (accessToken, application, cancelToken) => {
    const request = Axios.get('/api/v1/cms/languages', { cancelToken: cancelToken, headers: {'Authorization': 'Bearer ' + accessToken}, params: {application_uuid: application }})
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                resolve(res.data);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
}

export const getKeyFigures = (accessToken, type, params, cancelToken) => {
    const request = Axios.get('/api/v1/dashboard/' + type + '/keyfigures', { cancelToken: cancelToken, headers: {'Authorization': 'Bearer ' + accessToken}, params: params});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                const figures = Object.keys(res.data).map(i => {
                    return {
                        name:i, 
                        time:i.includes('duration'),
                        information:res.data[i]
                    }
                });
                resolve(figures);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
}

export const getDistribtion = (accessToken, type, option, params, cancelToken) => {
    const request = Axios.get('/api/v1/dashboard/' + type + '/distribution/' + option, { cancelToken: cancelToken, headers: {'Authorization': 'Bearer ' + accessToken}, params: params});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                const dataValues = Object.values(res.data);
                dataValues.sort((data1, data2) => data2.count - data1.count) ;
                const data = {
                    datasets: [{
                        labels: dataValues.map(item => item.name),
                        data: dataValues.map(item => item.count),
                        backgroundColor: [],
                        borderWidth: 0,
                    }],
                }
                resolve(data);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
}

export const getGraphData = (accessToken, type, option, scale, params, cancelToken) => {
    const request = Axios.get('/api/v1/dashboard/' + type + '/' + option + '/' + scale, { cancelToken: cancelToken, headers: { 'Authorization': 'Bearer ' + accessToken }, params: params});
    return new Promise((resolve, reject) => {
        request.then(res => {
            if(res.data) {
                let data = {
                    labels: Object.keys(res.data),
                    datasets: [{
                        label: type.charAt(0).toUpperCase() + type.slice(1),
                        fill: false,
                        borderColor: '#268EDC',
                        borderWidth: 3,
                        pointRadius: 4,
                        pointBorderColor: '#268EDC',
                        pointBackgroundColor: '#FFF',
                        pointBorderWidth: 3,
                        pointHoverRadius: 5,
                        pointHoverBorderColor: '#1678C2',
                        pointHoverBackgroundColor: '#FFF',
                        pointHoverBorderWidth: 3,
                    }],
                }
                switch(option) {
                    case "count":
                        data.datasets[0].data = Object.values(res.data);
                        switch (scale) {
                            case "hour":
                                data.labels = Object.keys(res.data).map(time => moment(time).format('HH:mm'));
                                break;
                            case "day":
                                data.labels = Object.keys(res.data).map(time => moment(time).format('D MMM'));
                                break;
                            case "week":
                                data.labels = Object.keys(res.data).map(time => "Week " + time);
                                break;
                            case "month":
                                data.labels = Object.keys(res.data).map(time => moment(time, 'MM').format('MMMM'));
                                break;
                            default:
                                break;
                        }
                        break;
                    case "average":
                        data.labels = scale === 'timeofday' ? Object.keys(res.data).map(time => moment(time, 'H').format('HH:mm')) : Object.keys(res.data).map(time => moment(time, 'e').format('dddd'));
                        data.datasets[0].data = Object.values(res.data).map(info => info.session_count);
                        break;
                    default:
                        break;
                }
               
                resolve(data);
            }
            else {
                reject("Problem with data");
            }
        }, reject => console.log(reject))
        request.catch(err => {
            if(err) {
                reject(err);
            }
        })
    });
}