import React from 'react'
import './IconButton.scss';

import className from 'classnames';

export default function IconButton(props) {
    return (
        <div className={className("icon-button", props.className)} onClick={props.onClick}>
            {props.children}
        </div>
    )
}
