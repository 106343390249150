import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import './LineGraph.scss';
import { ReactComponent as RefreshIcon } from '../../../../assets/svg/refresh.svg';
import PropTypes from 'prop-types';
import Button from '../../../ui/button/Button';
import { Chart } from 'react-chartjs-2'
import Loader from '../../../ui/loader/Loader';
import moment from 'moment';

Chart.Tooltip.positioners.custom = (elements, position) => {
    if (!elements.length) {
        return false;
    }

    let offset = 0;
    if (elements[0]._chart.height < position.y + 200) {
        offset = 9;
        elements[0]._chart.options.tooltips.yAlign = "bottom";
    } else {
        offset = -9;
        elements[0]._chart.options.tooltips.yAlign = "top";
    }

    return {
        x: elements[0]._model.x,
        y: elements[0]._model.y - offset,
    }
}

class LineGraph extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            options: [
                'Total', 
                'Average',
            ],
            totalScales: ['Hour', 'Day', 'Week', 'Month'],
            averageScales: ['Time of day', 'Day of week'],
            selectedOption: 'Total',
            selectedTotalScale: 'Day',
            selectedAverageScale: 'Day of week',
        }
    }

    chartReference = {};

    defaultData = {
        labels: ['1 Oct', '2 Oct', '2 Oct'],
        datasets: [{
            label: this.props.title,
            data:[0,0,0],
            fill: false,
            borderColor: '#268EDC',
            borderWidth: 3,
            pointRadius: 4,
            pointBorderColor: '#268EDC',
            pointBackgroundColor: '#FFF',
            pointBorderWidth: 3,
            pointHoverRadius: 4,
            pointHoverBorderColor: '#0A2F45',
            pointHoverBackgroundColor: '#FFF',
            pointHoverBorderWidth: 3,
        }],
    };

    handleScaleChange = (option, selectedScale) => {
        switch(option) {
            case 'Total': 
                this.setState({ selectedTotalScale: selectedScale });
                break;
            case 'Average': 
                this.setState({ selectedAverageScale: selectedScale });
                break;
            default:
                break;
        }
        this.setState({ selectedScale });
        this.props.optionsChange(option, selectedScale);
    }

    handleOptionChange = selectedOption => {
        this.setState({ selectedOption });
        switch(selectedOption) {
            case 'Total': 
                this.handleScaleChange(selectedOption, this.state.selectedTotalScale)
                break;
            case 'Average': 
                this.handleScaleChange(selectedOption, this.state.selectedAverageScale)
                break;
            default:
                break;
        }
    }
    
    render() {
        let options = {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 28,
                    right: 42,
                    top: 10,
                }
            },
            elements: {
                line: {
                    tension: .35
                }
            },
            scales: {
                xAxes: [{
                    gridLines : {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: '#A5A9B1',
                        fontFamily: 'roboto',
                        fontSize: 13,
                        fontStyle: 500,
                    }
                }],
                yAxes: [{
                    gridLines : {
                        display : false,
                        drawBorder: false
                    },
                    ticks: {
                        maxTicksLimit: 7,
                        precision: 0,
                        beginAtZero: true,
                        fontColor: '#A5A9B1',
                        fontFamily: 'roboto',
                        fontSize: 13,
                        fontStyle: 500,
                    }
                }]
            },
            onClick : (event, element) => {
                if(this.props.data) {
                    console.log(moment(this.props.data.labels[element[0]._index]));
                }
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    title: (tooltipItem, data) => {
                        return null;
                    },
                    label: (tooltipItem, data) => {
                        return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' ' + this.props.title.toLowerCase();
                    }
                },
                backgroundColor: 'rgba(10, 47, 69, 0.8)',
                cornerRadius: 5,
                xPadding: 10,
                yPadding: 8,
                bodyFontFamily: 'roboto',
                bodyFontSize: 12,
                bodyFontStyle: 500,
                yAlign: 'bottom',
                xAlign: 'center',
                position: 'custom',
            }
        };
        let scaleButtons = null;
        let optionButtons = null;
        if(!this.props.hideOptions) {
            optionButtons = this.state.options && this.state.options.length > 0 ? this.state.options.map((option, index) => {
                return <div key={index} className="menu-item"><Button deviceGroup selected={this.state.selectedOption === option} onClick={() => this.handleOptionChange(option)}>{option}</Button></div>;
            }): null;

            if(this.state.options && this.state.options.length > 0) {
                switch(this.state.selectedOption) {
                    case 'Total': 
                        scaleButtons = this.state.totalScales && this.state.totalScales.length > 0 ? this.state.totalScales.map((scale, index) => {
                            return <div key={index} className="menu-item"><Button deviceGroup selected={this.state.selectedTotalScale === scale} onClick={() => this.handleScaleChange(this.state.selectedOption, scale)}>{scale}</Button></div>;
                        }): null;
                        break;
                    case 'Average': 
                        scaleButtons = this.state.averageScales && this.state.averageScales.length > 0 ? this.state.averageScales.map((scale, index) => {
                            return <div key={index} className="menu-item"><Button deviceGroup selected={this.state.selectedAverageScale === scale} onClick={() => this.handleScaleChange(this.state.selectedOption, scale)}>{scale}</Button></div>;
                        }): null;
                        break;
                    default:
                        break;
                }
            }
        }
        else {
            scaleButtons = this.state.totalScales && this.state.totalScales.length > 0 ? this.state.totalScales.map((scale, index) => {
                return <div key={index} className="menu-item"><Button deviceGroup selected={this.state.selectedTotalScale === scale} onClick={() => this.handleScaleChange(this.state.selectedOption, scale)}>{scale}</Button></div>;
            }): null;
        }

        return (
            <div className="line-holder">
                <header>
                    <h6>{this.props.title}</h6>
                    <div className="option-options">
                        {optionButtons}
                    </div>
                    <div className="scale-options">
                        {scaleButtons}
                    </div>
                    <RefreshIcon className="refresh-button" onClick={() => this.props.onRefresh()}/>
                </header>
                <div className="graph">
                    {this.props.data && !this.props.isLoading ? 
                    <Line data={this.props.data} options={options} height={240} width={240} ref={(reference) => this.chartReference = reference}/> : 
                    <div className="loading-surface">
                        <Loader/>
                    </div>}
                </div>
            </div>
        )
    }
}

LineGraph.propTypes = {
    data: PropTypes.object,
    onRefresh: PropTypes.func.isRequired,
    title: PropTypes.string,    
};

export default LineGraph;
