import React, { Component } from 'react'
import './InputField.scss'
import { ReactComponent as EyeIcon } from '../../../assets/svg/eye.svg';

import classNames from 'classnames';
import PropTypes from 'prop-types';

class Input extends Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            hasFocus: false,
            showPassword: false,
        };
    };

    onFocusChanged = focussed => {
        this.setState({hasFocus: focussed})
    }

    toggleShow = show => {
        this.setState({ showPassword: show });
    }
    
    render() {
        const error = this.props.error ? 
            <p className={"error"}>
                {this.props.error}
            </p> : null;
        const eye = this.props.type === "password" ? 
        <EyeIcon 
            className={"eye"} 
            onMouseDown={() => this.toggleShow(true)} 
            onMouseUp={() => this.toggleShow(false)} 
            onMouseLeave={() => this.toggleShow(false)}
        /> : null;
        return (
            <div className={classNames("field", this.props.className, { "field-focussed": this.state.hasFocus, "field-error": this.props.error })}>
                <p className={"label"}>{this.props.label}</p>
                <input 
                    type={this.props.type && !this.state.showPassword ? this.props.type : ""} 
                    className={"input"} 
                    placeholder={this.props.placeholder} 
                    onFocus={() => this.onFocusChanged(true)} 
                    onBlur={() => this.onFocusChanged(false)}
                    onChange={this.props.onChange}
                    autoComplete={this.props.autoComplete}
                    spellCheck={false}
                    autoCapitalize="off" />
                {eye}
                <div className={"underline"}/>
                {error}
            </div>
        )
    }
}

Input.propTypes = {
    autoComplete: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
}

export default Input;