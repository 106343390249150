import React, { Component } from 'react'
import './Layout.scss'
import { ReactComponent as TapartIcon } from '../../assets/svg/logo.svg';
import Authentication from '../authentication/Authentication';
import CustomerSelect from '../authentication/customerSelect/CustomerSelect';
import Dashboard from '../dashboard/Dashboard';
import { BrowserRouter as Router } from "react-router-dom";

import { getCustomers } from "../../api/Api";

var classNames = require('classnames');

const Logo = (props) => (
    <div className={classNames('logo', { logoRight: props.right })}>
        <TapartIcon />
    </div>
)

class Layout extends Component {

    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user'));
        const customers = JSON.parse(localStorage.getItem('customers'));
        const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));
    
        if(user != null) {
            getCustomers(user.accessToken)
            .catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        console.log("Key invalid, please login again.");
                    }
                    this.logoutHandler();                    
                }
                else {
                    this.logoutHandler();
                }
            })
        }

        this.state = {
            user: user,
            selectedCustomer: selectedCustomer,
            customers: customers,
        };
    };
    

    loginHandler = data => {
        const user = { accessToken: data.access, refreshToken: data.refresh };
        this.setState({ user: user});
        localStorage.setItem('user', JSON.stringify(user));
    }

    logoutHandler = () => {
        this.setState({user: null, selectedCustomer: null, customers: null});
        localStorage.removeItem('user');
        localStorage.removeItem('customers');
        localStorage.removeItem('selectedCustomer');
    }

    setCustomers = customers => {
        this.setState({customers: customers});
        localStorage.setItem('customers', JSON.stringify(customers));
    }

    selectCustomerHandler = customer => {
        this.setState({selectedCustomer: customer});
        localStorage.setItem('selectedCustomer', JSON.stringify(customer));
    }

    render() {
        const authentication = <div className="container">
            <Authentication onLoggedIn={this.loginHandler}/>
        </div>;

        const customerSelect = <div className="container">
            <CustomerSelect setCustomers={this.setCustomers} user={this.state.user} onGoBack={this.logoutHandler} onSelect={this.selectCustomerHandler}/>
        </div>;

        const dashboard = <Dashboard user={this.state.user} customers={this.state.customers} customer={this.state.selectedCustomer} onCustomerSelect={this.selectCustomerHandler} onLogout={this.logoutHandler}/>;
        
        return (
            <Router>
                <div>   
                    <Logo right/>
                    {this.state.user ? this.state.selectedCustomer ? dashboard : customerSelect : authentication}
                </div>
            </Router>
        )
    }
}

export default Layout;
