import React, { Component } from 'react'
import './Button.scss'

import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader';

class Button extends Component {
    render() {
        return (
            <button 
                className={classNames("button", this.props.className, {'button-outline': this.props.outline, 'button-selected': this.props.selected, 'button-number': this.props.number, 'button-outline-pag': this.props.pagination, 'button-device-group': this.props.deviceGroup})} 
                disabled={this.props.loading || this.props.disabled} 
                onClick={this.props.onClick}>
                {this.props.loading ? <Loader inButton/> : <div className="content-div">{this.props.icon ? this.props.icon : null}{this.props.children}</div>}
            </button>
        )
    }
}

Button.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
}

export default Button;
