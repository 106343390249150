import React, { Component } from 'react';
import Button from '../../ui/button/Button';
import Input from '../../ui/inputField/Input';

import PropTypes from 'prop-types';

class PasswordRecovery extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            password: "",
            passwordError: "",
            retypedPassword: "",
            retypedPasswordError: "",
        };

        this.handlePasswordChange.bind(this);
    };
        
    handlePasswordChange = event => {
        this.setState({password: event.target.value});
        this.setState({passwordError: ""});
    }

    handleRetypedPasswordChange = event => {
        this.setState({retypedPassword: event.target.value});
        this.setState({retypedPasswordError: ""});
    }

    submitHandler = event => {
        event.preventDefault();
        if(!this.state.password) {
            this.setState({passwordError: "No password specified."});
        }
        else if(!this.state.retypedPassword) {
            this.setState({retypedPasswordError: "No retyped password specified."});
        }
        else if(this.state.password === this.state.retypedPassword) {
            this.props.onSubmit(this.state.password);
        }
        else {
            this.setState({retypedPasswordError: "Passwords don't match."});
        }
    }

    render() {
        const form = this.props.status.success ? <p>{this.props.status.message}</p> : 
        <div>
            <p>Use at leas 8 characters. The password can consist of lowercase letters, uppercase letters, digits and symbols.</p>
            <form>
                <Input 
                    className={"password"}
                    label={"Password"} 
                    type={"password"}
                    autoComplete={"new-password"}
                    onChange={this.handlePasswordChange}
                    error={this.state.passwordError || (!this.props.status.success ? this.props.status.message : "")}
                />
                <Input 
                    className={"retypedPassword"}
                    label={"Repeat password"} 
                    type={"password"}
                    autoComplete={"new-password"}
                    onChange={this.handleRetypedPasswordChange}
                    error={this.state.retypedPasswordError}
                />
                <Button onClick={this.submitHandler} loading={this.state.loading}>Save password</Button>
            </form>
        </div>
        
        return (
            <div className="root">
                <h1>Choose a new password</h1>
                {form}
            </div>
        )
    }
}

PasswordRecovery.propTypes = {
    onSubmit: PropTypes.func,
    status: PropTypes.object,
    loading: PropTypes.bool,
}

export default PasswordRecovery;
