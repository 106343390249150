import React, { Component } from 'react';
import './TourAnalytics.scss';
import { getGraphData } from '../../../../api/Api';
import LineGraph from '../LineGraph/LineGraph';

class TourAnalytics extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            tourData: null,
            tourOptions: {
                option: 'count',
                scale: 'day',
            }
        }
    }

    componentDidMount() {
        this.updateTours();
    }

    componentDidUpdate(prevProps, prevState){ 
        if(prevProps !== this.props) {
            this.updateTours();
        }
        if(prevState.tourOptions !== this.state.tourOptions) {
            this.updateTours();
        }
    }

    updateTours = () => {
        this.setState({ isGraphLoading: true });
        getGraphData(this.props.user.accessToken, 'tours', this.state.tourOptions.option, this.state.tourOptions.scale, this.props.params)
        .then(tourData => {
            this.setState({ tourData, isGraphLoading: false });
        })
        .catch(err => {
            this.setState({ isGraphLoading: false })
            console.log(err.response);
        })
    }

    handleOptionChange = (option, scale) => {
        const tourOptions = {
            option: option === 'Total' ? 'count' : option.toLowerCase(),
            scale: scale.replace(/\s/g,'').toLowerCase(),
        };
        this.setState({ tourOptions });
    }
    
    render() {
        return (
            <div className="tour-analytics">
                <LineGraph onRefresh={this.updateTours} title="Tours" data={this.state.tourData} hideOptions optionsChange={this.handleOptionChange} isLoading={this.state.isGraphLoading}/>
            </div>
        )
    }
}

export default TourAnalytics;