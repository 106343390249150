import React from 'react';

import './CheckBox.scss';
import { ReactComponent as Check } from '../../../assets/svg/check.svg';
import classNames from 'classnames';

export default function CheckBox(props) {
    return (
        <div className={classNames("check-box", props.className, {'check-box-selected': props.checked})} onClick={props.onClick}>
            <Check className="check"/>
        </div>
    )
}
