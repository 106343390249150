import React, { Component } from 'react';
import './TabLayout.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TabLayout extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            tabs: props.tabs,
            selectedTab: props.tabs[0].value,          
        }
    }

    tabChangeHandler = selectedTab => {
        this.setState({ selectedTab });
        this.props.onChange(selectedTab);
    }

    render() {
        const options = this.state.tabs.map(tab => {
            return (
                <div className={classNames("tab", this.props.className, { "tab-selected": tab.value === this.state.selectedTab })} key={tab.value} onClick={() => this.tabChangeHandler(tab.value)}>
                    <p>{tab.name}</p>
                    <div/>
                </div>
            );
        })

        return (
            <div className="tab-layout">
                {options}
            </div>
        )
    }
}

TabLayout.defaultProps = {
    onChange: tab => console.log(tab),
    tabs: [
        {name: 'Tab 1', value: 'default'},
    ],
}

TabLayout.propTypes = {
    tabs: PropTypes.array,
    onChange: PropTypes.func,
}

export default  TabLayout;