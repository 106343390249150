import React from 'react';
import './InfoBox.scss';
    
const pad = num => {
    return ("0"+num).slice(-2);
}

const translateTitle = titleId => {
    switch (titleId) {
        case 'total_segments':
            return "Total segments";
        case 'total_stops':
            return "Total stops";
        case 'total_tours':
            return "Total tours";
        case 'total_sessions':
            return "Total sessions";

        case 'avg_session_duration_sec':
            return "Average sessions duration";
        case 'avg_tour_duration_sec':
            return "Average tour duration";
        case 'avg_stop_duration_sec':
            return "Average stop duration";
        case 'avg_segment_duration_sec':
            return "Average segment duration";

        case 'avg_sessions_per_day':
            return "Average sessions per day";
        case 'avg_tours_per_day':
            return "Average tour per day";
        case 'avg_stops_per_day':
            return "Average stops per day";
        case 'avg_segments_per_day':
            return "Average segments per day";

        case 'avg_stops_per_session':
            return "Average stops per session";
        
        default:
            return titleId;
    }
}

const hhmmss = secs => {
    var minutes = Math.floor(secs / 60);
    var seconds = secs % 60;
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${hours}:${pad(minutes)}:${pad(seconds)}`;
}

const thousandSeperator = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function InfoBox(props) {

    const title = translateTitle(props.title);
    const info = props.time ? hhmmss(Math.round(props.info)) : thousandSeperator(Math.round(props.info));

    return (
        <div className="info-box">
            <h6>{info}</h6>
            <p>{title}</p>
        </div>
    )
}
