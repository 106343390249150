import React, { Component } from 'react';
import './SessionAnalytics.scss';
import DougnutGraph from '../DougnutGraph/DougnutGraph';
import { getDistribtion, getGraphData, hasNull } from '../../../../api/Api';
import LineGraph from '../LineGraph/LineGraph';
import Axios from 'axios';

class SessionAnalytics extends Component {
    
    CancelToken = Axios.CancelToken;
    languageCancel;
    tourCancel;
    sessionCancel;

    constructor(props) {
        super(props)
    
        this.state = {
            languageData: null,            
            tourData: null,
            sessionData: null,
            languageDataLoading: false,
            tourDataLoading: false,
            sessionDataLoading: false,
            sessionOptions: {
                option: 'count',
                scale: 'day',
            }
        }
    }

    componentWillUnmount() {
        if(this.languageCancel) {
            this.languageCancel("Closed screen");
        }
        if(this.tourCancel) {
            this.tourCancel("Closed screen");
        }
        if(this.sessionCancel) {
            this.sessionCancel("Closed screen");
        }
    }

    componentDidMount() {
        this.updateTours();
        this.updateLanguages();
        this.updateSessions();
    }

    componentDidUpdate(prevProps, prevState) { 
        if(prevProps !== this.props) {
            this.updateTours();
            this.updateLanguages();
            this.updateSessions();
        }
        if(prevState.sessionOptions !== this.state.sessionOptions) {
            this.updateSessions();
        }
    }

    updateLanguages = () => {
        if(hasNull(this.props.params)) {
            return;
        }
        this.languageCancel && this.languageCancel("New request");
        // this.setState({ languageDataLoading: true })
        getDistribtion(this.props.user.accessToken, 'sessions', 'languages', this.props.params, new Axios.CancelToken(c => this.languageCancel = c))
        .then(languageData => {
            let total = 0;
            Object.values(languageData.datasets[0].data).forEach(number => total += number);
            languageData.datasets[0].backgroundColor = [
                '#F7681E',
                '#06BBD1',
                '#DB162F',
                '#20BF55',
                '#F2B724',
                '#9E35F4',
                '#EC0092',                
                '#268EDC',
            ];
            languageData.total = total;
            this.setState({ languageData, languageDataLoading: false })
        })
        .catch(err => {
            this.setState({ languageDataLoading: false })
            if(err && err.response) {
                console.log(err.response);
            }
        })
    }

    updateSessions = () => {
        if(hasNull(this.props.params)) {
            return;
        }
        this.sessionCancel && this.sessionCancel("New request");
        // this.setState({ sessionDataLoading: true });
        getGraphData(this.props.user.accessToken, 'sessions', this.state.sessionOptions.option, this.state.sessionOptions.scale, this.props.params, new Axios.CancelToken(c => this.sessionCancel = c))
        .then(sessionData => {
            this.setState({ sessionData, sessionDataLoading: false });
        })
        .catch(err => {
            this.setState({ sessionDataLoading: false })
            if(err && err.response) {
                console.log(err.response);
            }
        })
    }

    updateTours = () => {
        if(hasNull(this.props.params)) {
            return;
        }
        this.tourCancel && this.tourCancel("New request");
        // this.setState({ tourDataLoading: true });
        getDistribtion(this.props.user.accessToken, 'sessions', 'tours', this.props.params, new Axios.CancelToken(c => this.tourCancel = c))
        .then(tourData => {
            let total = 0;
            Object.values(tourData.datasets[0].data).forEach(number => total += number);
            tourData.datasets[0].backgroundColor = [
                '#268EDC',
                '#DB162F',
            ]
            tourData.total = total;
            this.setState({ tourData, tourDataLoading: false })
        })
        .catch(err => {
            if(err.response) {
                console.log(err.response);
            }
            this.setState({ tourDataLoading: false })
        })
    }

    handleOptionChange = (option, scale) => {
        const sessionOptions = {
            option: option === 'Total' ? 'count' : option.toLowerCase(),
            scale: scale.replace(/\s/g,'').toLowerCase(),
        };
        this.setState({ sessionOptions });
    }
    
    render() {
        return (
            <div className="session-analytics">
                <LineGraph onRefresh={() => this.updateSessions()} title="Sessions" data={this.state.sessionData} optionsChange={this.handleOptionChange} isLoading={this.state.sessionDataLoading}/>
                <div className="doughnuts">
                    <DougnutGraph onRefresh={() => this.updateLanguages()} title="Languages" total={this.state.languageData ? this.state.languageData.total : null} totalTitle={"Total sessions"} data={this.state.languageData} isLoading={this.state.languageDataLoading}/>
                    <DougnutGraph onRefresh={() => this.updateTours()} title="Tours" total={this.state.tourData ? this.state.tourData.total : null} totalTitle={"Total tours"} data={this.state.tourData} isLoading={this.state.tourDataLoading}/>
                </div>
            </div>
        )
    }
}

export default SessionAnalytics;