import React, { Component } from 'react';
import './BarGraph.scss';
import { HorizontalBar } from 'react-chartjs-2';
import { ReactComponent as RefreshIcon } from '../../../../assets/svg/refresh.svg';
import { Chart } from 'react-chartjs-2';
import Loader from '../../../ui/loader/Loader';

Chart.Tooltip.positioners.custom = (elements, position) => {
    if (!elements.length) {
        return false;
    }
    let offset = 0;
    if (elements[0]._chart.height < position.y + 200) {
        offset = 9;
        elements[0]._chart.options.tooltips.yAlign = "bottom";
    } else {
        offset = -9;
        elements[0]._chart.options.tooltips.yAlign = "top";
    }

    return {
        x: elements[0]._model.x,
        y: elements[0]._model.y - offset,
    }
}

class BarGraph extends Component {

    currentHeight = 0;
    previousHeight = 0;

    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    chartReference = {};

    redraw = () => {
        const redraw = this.previousHeight !== this.currentHeight;
        this.previousHeight = this.currentHeight;
        return redraw;
    }

    render() {
        let options = {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 28,
                    right: 42,
                    top: 10,
                }
            },
            elements: {
                line: {
                    tension: .35
                }
            },
            scales: {
                xAxes: [{
                    gridLines : {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: '#A5A9B1',
                        fontFamily: 'roboto',
                        fontSize: 13,
                        fontStyle: 500,
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        stepSize: 1
                    }
                }],
                yAxes: [{
                    barThickness: 15,
                    gridLines : {
                        display : false,
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: '#A5A9B1',
                        fontFamily: 'roboto',
                        fontSize: 13,
                        fontStyle: 500,
                        beginAtZero: true,
                    }
                }]
            },
            animation: {
                animateScale: true
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    title: (tooltipItem, data) => {
                        return null;
                    },
                    label: (tooltipItem, data) => {
                        return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' ' + this.props.title.toLowerCase();
                    }
                },
                backgroundColor: 'rgba(10, 47, 69, 0.8)',
                cornerRadius: 5,
                xPadding: 10,
                yPadding: 8,
                bodyFontFamily: 'roboto',
                bodyFontSize: 12,
                bodyFontStyle: 500,
                yAlign: 'bottom',
                xAlign: 'center',
                position: 'custom',
            }
        };

        const height = this.props.data ? this.props.data.datasets[0].data.length * 39 + 50 : 0;
        this.currentHeight = height;
        const redraw = this.redraw();

        return (
            <div className="bar-holder" key={"" + (this.props.data ? this.props.data.datasets[0].data.length : 0)}>
                <header>
                    <h6>{this.props.title}</h6>
                    <RefreshIcon className="refresh-button" onClick={() => this.props.onRefresh()}/>
                </header>
                <div className="graph">
                    {this.props.data ? <HorizontalBar data={this.props.data} options={options} height={height} ref={(reference) => this.chartReference = reference} redraw={redraw}/> : <div className="loading-surface"><Loader/></div>}
                </div>
            </div>
        )
    }
}

export default BarGraph;