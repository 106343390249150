import React, { Component } from 'react';
import './SegmentAnalytics.scss';
import BarGraph from '../BarGraph/BarGraph';
import { getDistribtion } from '../../../../api/Api';

class SegmentAnalytics extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            segmentData: null,
        }
    }

    componentDidMount() {
        this.updateSegments();
    }

    componentDidUpdate(prevProps){ 
        if(prevProps !== this.props) {
            this.updateSegments();
        }
    }

    updateSegments = () => {
        getDistribtion(this.props.user.accessToken, 'sessions', 'segments', this.props.params)
        .then(data => {
            const segmentData = data;
            segmentData.labels = data.datasets[0].labels;
            segmentData.datasets[0].backgroundColor = '#268EDC';
            segmentData.datasets[0].hoverBackgroundColor = '#1678C2';
            segmentData.datasets[0].barThickness = 15;
            this.setState({ segmentData });
        })
    }

    render() {
        return (
            <div className="segment-analytics">
                <BarGraph onRefresh={this.updateSegments} title="Segments" data={this.state.segmentData}/>
            </div>
        )
    }
}

export default SegmentAnalytics;